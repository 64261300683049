.error {
  color: red;
}

.warning {
  color: red;
}

.m-form__help.text-danger {
  display: inline-block;
  width: 100%;
}

.m-form__help.text-danger:empty {
  display: none;
}

.main-content-inner {
}

.stripe_marginBottom {
  margin-bottom: 20px;
}

.img_tag {
  margin-left: -12px;
}

.btn--loading {
  background: #009cdc url("/img/dual-ring.svg") center no-repeat;
  background-size: 50px 50px;
  color: rgba(255, 255, 255, 0);
}

#button-float-right {
  width: 150px;
  margin-bottom: 25px;
  float: right;
  margin-left: 10px;
}

#button-float {
  width: 150px;
  margin-bottom: 25px;
  margin-left: 2px;
}

.onboard-heading-lead {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  text-align: center;
}

.logo h2 {
  color: #f7f7f7;
}

.sidebar-menu,
.sidebar-header {
  background: #292b2c;
}

.metismenu li a {
  color: #f7f7f7;
}

.customer-registr-btn {
  width: 150px;
  margin-bottom: 25px;
}

//.custom-control-invoice::after{
//  background-color: #6c757d;
//}
.invoice-list-delete {
  cursor: pointer;
  max-width: 5%;
  height: 30px;
  position: absolute;
  left: 30px;
  top: -30px;
  color: #007bff;
}

button[name="singleId"] {
  background-color: transparent !important;
}

.btn-non-edit-invoice {
  width: 150px;
  margin-bottom: 25px;
  margin-right: 3px;
}

.btn_non_edit {
  text-align: center;
}

.invoice-buttons-invoice a {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  margin-left: 0;
  background-color: #6c757d;
  padding: 11px 17px;
  font-size: 13px;
}

.invoice-buttons-invoice a:hover {
  background: #5a6268;
}

.main-content-inner select.form-control {
  margin-bottom: 0;
}

.sidebar-menu {
}

.react-datepicker-wrapper {
  position: relative;
  bottom: 42px;
  left: 3px;
}

.react-datepicker__input-container input {
  padding: 9px 30px;
  border: 1px #e5e5e5;
}

.modal-content {
  border: 1px white;
}

button.close {
  border-left: 1px solid #d5d5d5;
  border-right: 1px #d5d5d5;
  border-bottom: 1px white;
}

.form-group-modal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.form-group-modal-check {
  justify-content: flex-start;
}

.form-non-edit-modal {
  max-width: 380px;
  width: 100%;
  border: 1px solid #ccc;
}

.form-non-edit-label {
  margin-bottom: 0;
  margin-right: 5px;
}

.modal-message {
  border: 1px solid #c6c8ca;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 10px;
  line-height: 30px;
}

textarea.modal-message {
  width: 376px;
}

.btn-secondary-modal {
  background-color: #979b9e;
  border-color: #979b9e;
}

.btn_modal {
  padding: 5px 17px;
  font-size: 15px;
  font-weight: 700;
}

.btn-send-modal {
  background-color: #52c13e;
  border-color: #52c13e;
}

.before-checkbox {
  width: 60px;
}

.label-invoice {
  color: #212529;
}

.modal-header-modal {
  padding: 10px;
  margin: 0 12px;
}

.modal-header-modal h5 {
  font-weight: 600;
}

.modal-header-modal button {
  font-weight: 800;
  margin: -1rem 0rem -1rem auto !important;
}

.modal-header-modal button span {
  position: relative;
  left: 12px;
}

.proposal_button button {
}

span.table-add,
td.table-add {
  // cursor: pointer;
}

.input_border_none {
  border: 1px white;
}

.pointer-cursor {
  cursor: pointer;
}

/* invoice pages */

.invoice-col {
  position: relative;
}

.invoice-col span.invoice-error {
  position: absolute;
  bottom: 0;
  left: 7px;
  font-size: 10px;
}

/* Autosuggestions */

div[role="combobox"] input {
  display: block;
  width: 100%;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  border: 1px solid rgba(170, 170, 170, 0.3);
  padding: 0.67rem 0.8rem;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  text-align: left;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 43px;
  width: 280px;
  border: 1px solid rgba(170, 170, 170, 0.3);
  background-color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

td.table-add div.react-autosuggest__container input {
  border: 1px white;
}

.modal_checkbox_parent {
  padding-left: 60px;
}

.contact-actions {
  display: inline-block;
  margin-top: -7px;
}

.accept_button {
  margin-right: 0;
}

tr.row-textcenter {
  text-align: center;
}

a.proposal_preview {
  display: inline-block;
  font-size: 13px;
  color: #fff;
  background: #6c757d;
  padding: 11px 21px;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em;
  border-radius: 4px;
}

button.accept_close {
  margin-bottom: 0;
}

a.proposal_preview:hover {
  background-color: #767e86;
}

.payment-plan .react-datepicker__input-container input {
  padding: 9px 19px;
  border: 1px #e5e5e5;
  width: 97%;
}

.border_red {
  border: 1px solid red;
}

.correct_email_err {
  position: absolute;
  top: 51px;
  left: 101px;
}

///////TAGS///////
.tags {
  background: #fff;
  padding: 0px;
  overflow: hidden;
  //display:inline-flex;
  width: 100%;
}

.tags div.parent_div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.parent_div div {
  position: relative;
}

div.parent_div a {
  position: absolute;
  right: 3px;
  bottom: 12px;
  padding: 0 3px 0 3px;
  font-size: 10px;
  cursor: pointer;
}

.tags input {
  height: 44px;
}

.tag {
  float: left;
  padding: 0 10px;
  line-height: 44px;
  font-family: "Lato", sans-serif;
  color: #666;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 0 3px 3px;
  height: 44px;

  button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}

.tag-input {
  width: 50%;
}

code {
  font-size: 12px;
  background: #fcf8d0;
  display: inline-block;
  font-family: courier;
  padding: 4px 6px;
  border-radius: 4px;
}

// small {
//   color: #7f8c8d;
//   font-size: 14px;
//   margin-top: 10px;
//   display: block;
//   line-height: 16px;
// }

.table_th_right {
  text-align: end;
}

.table_th_left {
  text-align: start;
}

small.datePicker_text {
  position: absolute;
  top: 37.5%;
}

.label_margin {
  margin-left: 5px;
}

div.dropdown {
  margin-left: 15px;
}

input[type="file"] {
  border: 0px solid black;
}

.import_modal {
  width: 323px;
}

.import_modal_error {
  display: block;
  padding-left: 14px;
}
.organisation_datePicker {
  max-height: 78px;
  height: 78px;
}
.organisation_datePicker .react-datepicker-wrapper {
  max-width: 430px;
  width: 100%;
}
.organisation_datePicker .react-datepicker__input-container input {
  padding: 10px 13px;
}
.form-control .date_poker {
  height: 41px;
}
.my_modal_footer {
  border-top: 1px #e9ecef;
  padding: 2rem;
  display: block;
}

.lead-autosuggest #react-autowhatever-1 li:first-of-type {
  background-color: #ddd;
  font-weight: 700;
}

#dropdown-date {
  height: 45px;
  width: 200px;
}

#text-number {
  text-align: right;
}

#row-report {
  padding-top: -15px;
  margin-top: -15px;
}

#text-center {
  text-align: center;
}

#row-report-sum {
  padding-top: -15px;
  margin-top: -15px;
  border-bottom: 1px solid black;
}
#row-report-sum-t {
  padding-top: -15px;
  margin-top: -15px;
  border-top: 1px solid black;
}
#text-report-header {
  text-align: right;
  font-weight: bold;
}
//////////////////////////////////////////
.button-center {
  display: flex;
  justify-content: center;
}

.btn-secondary {
  width: 150px;
  // margin: 10px;
}

#dashboard-performance h5 {
  text-align: center;
}

.table-performance h6 {
  text-align: center;
}

#dashboard-attention h5 {
  text-align: center;
}

.table-dash {
  font-size: small;
  width: 80%;
  margin: 0 auto;
}

.table-dash td {
  height: 22px;
}

.table-dash th {
  height: 22px;
  text-align: center;
}

.table-number {
  text-align: right;
}
.dashboard_tables a {
  font-family: "Lato", sans-serif;
}
.btn_reject_color {
  background: #6c757d;
  margin-left: 2px;
  color: white;
}
.btn_reject_color:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn_cancel {
  background: #979b9e;
  color: white;
}
.import_upload_file {
  margin: auto;
  float: center;
}
.lest_checkbox {
  z-index: 1000;
}
.contact_modal_title {
  margin-left: 25px;
}
.error_message {
  font-size: 12px;
  border: 1px solid red;
  padding: 5px;
  line-height: 16px;
}

.text_low {
  text-transform: lowercase !important;
  font-weight: 500 !important;
}

.trash_name {
  font-size: 12px;
  position: absolute;
  left: 16px;
  bottom: 12px;
}
.invoice_list .react-datepicker__input-container input {
  padding-right: 175px;
  padding-bottom: 9px;
  padding-left: 14px;
  border: 1px;
}
.form-group_director {
  display: flex;
  align-items: start;
}
.form-group_director label {
  width: 100px;
}

div.link {
  cursor: pointer;
}

.sidebar-menu {
  background-color: #141414;
}

.sidebar-header {
  background-color: #141414;
}

.nav-btn pull-left {
  background-color: #141414;
}
.logo {
  color: #fff;
}

.c-buttons button {
  width: 150px;
}

.invoice-table {
  td {
    height: 45px !important;
  }
}

#pagination-first-page,
#pagination-previous-page,
#pagination-next-page,
#pagination-last-page {
  margin-top: 25px;
}

@media only screen and (max-width: 576px) {
  .react-autosuggest__container {
    width: 72% !important;
  }
}

// login page css
.password-show {
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 11px;
}

.login-form-head {
  background-color: #292b2c;
}

.login-heading-1 {
  color: #f7f7f7;
  text-align: center;
}

.login-heading-2 {
  color: #f7f7f7;
  font-size: 20px;
  text-align: center;
}

.login-heading-3 {
  color: #292b2c;
  font-size: 18px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .login-heading-3 {
    font-size: 18px;
  }
}

.submit-btn-area button {
  border: 1px solid #c1c1c1;
}

.login-form-head {
  margin-bottom: -20px;
}

.custom-datepicker {
  .react-datepicker-wrapper {
    position: initial !important;
  }

  .form-control {
    border: 1px solid rgba(170, 170, 170, 0.3) !important;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
}

.checks {
  i.fa-check {
    color: #5cb85c;
  }
}

.remove-value {
  position: absolute;
  right: 10px;
  top: 11px;
}

.description-editor {
  height: auto !important;
}

.contacts-tab {
  .nav-item {
    width: 25%;
  }

  .nav-link {
    padding: 0.5rem 1rem !important;
  }
}

.markaspaid-picker {
  .react-datepicker-wrapper {
    width: 70%!important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .date-picker-mark-as-read {
    margin-left: 0 !important;
    margin-top: 0 !important;
    height: 44px !important;
  }

  .date-span {
    width: 100% !important;
  }

  .input-group-text {
    margin-left: 0 !important;
  }
}

.table-editable tr.invoice-body td{
  vertical-align: top!important;
}

.table-editable tr.invoice-body td span{
  padding: 10px;
}

.p-10{
  padding: 10px!important;
}

.notes-editor{
  .DraftEditor-root{
    height: 180px;
  }
}


.description-editor{
  span{
    padding: 0px!important;
  }
}

.react-datepicker__navigation{
  width: 10px !important;
}